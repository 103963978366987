import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

const state = {
    dataAgendamento: "",
    medicoAgendamento: {},
    horarioAgendamentoAlterando: {},
    pacienteAgendamentoAlterando: {},
    localizacaoFisica: window.localStorage.getItem("localizacaoFisicaAgenda"),
    horarioAgendamentoAtender: {}, 
    telefoneBuscadoTelefonia: ""
};

const getters = {
    dataAgendamento(state) {
        return state.dataAgendamento;
    },
    medicoAgendamento(state) {
        return state.medicoAgendamento;
    },
    horarioAgendamentoAlterando(state) {
        return state.horarioAgendamentoAlterando;
    },
    pacienteAgendamentoAlterando(state) {
        return state.pacienteAgendamentoAlterando;
    },
    localizacaoFisica(state) {
        return state.localizacaoFisica;
    },
    horarioAgendamentoAtender(state) {
        return state.horarioAgendamentoAtender;
    },
    telefoneBuscadoTelefonia(state) {
        return state.telefoneBuscadoTelefonia;
    }
};

const mutations = {
    updateDataAgendamento(store, dataAgendamento) {
        store.dataAgendamento = dataAgendamento;
    },
    updateMedicoAgendamento(store, medicoAgendamento) {
        store.medicoAgendamento = medicoAgendamento;
    },
    updateHorarioAgendamentoAlterando(store, horarioAgendamentoAlterando) {
        store.horarioAgendamentoAlterando = horarioAgendamentoAlterando;
    },
    updatePacienteAgendamentoAlterando(store, pacienteAgendamentoAlterando) {
        store.pacienteAgendamentoAlterando = pacienteAgendamentoAlterando;
    },
    updateLocalizacaoFisica(store, localizacaoFisica) {
        window.localStorage.setItem("localizacaoFisicaAgenda", localizacaoFisica);
        store.localizacaoFisica = localizacaoFisica;
    },
    updateHorarioAgendamentoAtender(store, horarioAgendamentoAtender) {
        store.horarioAgendamentoAtender = horarioAgendamentoAtender;
    },
    updateTelefoneBuscadoTelefonia(store, telefoneBuscadoTelefonia) {
        store.telefoneBuscadoTelefonia = telefoneBuscadoTelefonia;
    }
};

const actions = {
    getMapaAgenda(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/mapa", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getAgendamentosHojePaciente(context, idPaciente) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/agendamentosHojePaciente", { ID_PACIENTE: idPaciente }).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getAgendamentosHojePacienteComChegada(context, idPaciente) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/agendamentosHojePacienteComChegada", { ID_PACIENTE: idPaciente }).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getAgendamento(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/obterAgendamento", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    informarChegada(context, idAgenda) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/informarChegada", { ID_AGENDA: idAgenda }).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    informarChegadaSemPendente(context, idAgenda) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/informarChegada", { ID_AGENDA: idAgenda, SEM_PENDENTE: true }).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    listarHorariosDisponiveis(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/listarHorariosDisponiveis", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    listarHorariosPeriodoDisponiveis(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/listarHorariosPeriodoDisponiveis", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    listarHorariosAgendados(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/listarHorariosAgendados", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    listarProcedimentos(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/listarProcedimentos", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    listarProcedimentosAgendados(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/listarProcedimentosAgendados", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    incluirAgendamento(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/incluirAgendamento", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    horariosDiposniveisMedico(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/horariosVagosPorMedico", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    atualizarDataAgendamento({ commit }, dataAgendamento) {
        commit('updateDataAgendamento', dataAgendamento);
    },
    atualizarMedicoAgendamento({ commit }, medicoAgendamento) {
        commit('updateMedicoAgendamento', medicoAgendamento);
    },
    atualizarHorarioAgendamentoAlterando({ commit }, horarioAgendamentoAlterando) {
        commit('updateHorarioAgendamentoAlterando', horarioAgendamentoAlterando);
    },
    atualizarPacienteAgendamentoAlterando({ commit }, pacienteAgendamentoAlterando) {
        commit('updatePacienteAgendamentoAlterando', pacienteAgendamentoAlterando);
    },
    atualizarTelefoneBuscadoTelefonia({ commit }, telefoneBuscadoTelefonia) {
        commit('updateTelefoneBuscadoTelefonia', telefoneBuscadoTelefonia);
    },
    listaAgendaAuxilia(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/listaAgendaAuxilia", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    incluiAgendaAuxiliaInclusao(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/incluiAgendaAuxiliaInclusao", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    removeAgendaAuxiliaInclusao(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/removeAgendaAuxiliaInclusao", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    numerosAgendamentosMedico(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/numerosAgendamentosMedico", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    atualizarAgendaStatus(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/alterarStatus", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    excluirBloqueioHorario(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/excluirBloqueioHorario", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    incluirBloqueioHorario(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/incluirBloqueioHorario", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    pegarAgendamentoParaUsuario(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/pegarAgendamentoParaUsuario", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    listarProcedimentosPacienteDoDia(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/listarProcedimentosPacienteDoDia", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    chamarNaTV(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/chamarNaTV", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    excluirAgendamento(context, idAgenda) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/excluir", {ID_AGENDA: idAgenda}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    atualizarLocalizacaoFisica({ commit }, localizacaoFisica) {
        commit('updateLocalizacaoFisica', localizacaoFisica);
    }, 
    incluirFaturamento(context, idAgenda) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/incluirFaturamento", {ID_AGENDA: idAgenda}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    atualizarHorarioAgendamentoAtender({ commit }, horarioAgendamentoAtender) {
        commit('updateHorarioAgendamentoAtender', horarioAgendamentoAtender);
    }, 
    getProcedimentoAgenda(context, idAgendaExame) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/obterProcedimentoAgenda", {ID_AGENDA_EXAME: idAgendaExame}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    listarAgendamentosPaciente(context, idPaciente) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/listarAgendamentosPaciente", {ID_PACIENTE: idPaciente}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    listarAgendamentosAposInclusao(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/listarAgendamentosAposInclusao", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    alterarAgendamentoDiaHora(context, dados) {
        return new Promise(resolve => {
            if(JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/alterarAgendamento", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    permiteRetornoPaciente(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/validaPermissaoRetornoPaciente", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    permiteRetornoPacienteNoMes(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/validaPermissaoAtendimentoMesPorPaciente", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    permiteRetornoPlanoNoMes(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/validaPermissaoAtendimentoMesPorPlano", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    permiteRetornoPlanoNoDia(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/validaPermissaoAtendimentoDiaPorPlano", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    permiteRetornoMedicoPlanoNoDia(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/validaPermissaoAtDiaPorPlanoMed", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    permiteRetornoMedicoPlanoProcNoDia(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/validaPermissaoAtDiaPorPlanoMedProc", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    permiteMedicoPlano(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/validaPermissaoPlanoPorMedico", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    permiteProcedimentoMedicoPlano(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/validaPermissaoProcedimentoPorMedico", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    verificaVagasPorPlanoMedico(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/verificaVagasPorPlanoMedico", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    salvaAgendasMultiplas(context, dados) {
        return new Promise(resolve => {
            if(JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("agendamentos/access/salvarMultiplas", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};