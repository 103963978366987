<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content" style="padding: 8px 0px;">
    <!--begin::Post-->
    <div class="post d-flex flex-column-fluid" id="kt_post">
      <router-view :key="$route.fullPath" v-slot="{ Component }">
        <transition enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
    <!--end::Post-->
  </div>
</template>

<script>
export default {
  name: "ContentMain"
}
</script>

<style scoped>

</style>