import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    getFilaEsperaAgenda(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("filasEsperaAgenda/access/listar", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    postFilaEsperaAgenda(context, dados) {
      return new Promise(resolve => {
          if(JwtService.getToken()) {
              ApiService.setHeader();
              ApiService.post("filasEsperaAgenda/access/salvar", dados).then(({ data }) => {
                  resolve(data);
              });
          }
      });
    }, 
    excluirFilaEsperaAgenda(context, dados) {
      return new Promise(resolve => {
          if(JwtService.getToken()) {
              ApiService.setHeader();
              ApiService.post("filasEsperaAgenda/access/excluir", dados).then(({ data }) => {
                  resolve(data);
              });
          }
      });
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};